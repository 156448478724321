import React, { useState } from "react"
import PropTypes from "prop-types"
import "twin.macro"
import Icon from "./icon"
import Close from "../images/svgs/close.svg"

const Copyright = ({ photographer, rights }) => {
  const [showCopyRight, setShowCopyRight] = useState(false)
  const singleField = photographer === rights
  return (
    <>
      <button
        tw="absolute right-0 top-0 z-20 pl-5 pb-5"
        onClick={() => setShowCopyRight(!showCopyRight)}
        aria-label={showCopyRight ? "schließen" : "coyright anzeigen"}
      >
        <span tw="flex h-6 w-6 items-center justify-center bg-sand-500 text-xl leading-none">
          {!showCopyRight && "©"}
          {showCopyRight && <Icon svg={Close} tw="h-3 w-3" />}
        </span>
      </button>
      {showCopyRight && (
        <div
          aria-hidden={!showCopyRight}
          tw="absolute z-10 h-full w-full bg-sand-500 px-5 pt-12"
        >
          <ul tw="text-sm">
            {!!singleField ? (
              <li>
                Foto & Rechte:
                <span tw="font-bold">&nbsp;{photographer || rights}</span>
              </li>
            ) : (
              <>
                {photographer && (
                  <li>
                    Foto:
                    <span tw="font-bold">&nbsp;{photographer}</span>
                  </li>
                )}
                {rights && (
                  <li>
                    Rechte:
                    <span tw="font-bold">&nbsp;{rights}</span>
                  </li>
                )}
              </>
            )}
          </ul>
        </div>
      )}
    </>
  )
}

Copyright.propTypes = {
  photographer: PropTypes.string,
  rights: PropTypes.string,
}

export default Copyright
